import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Url } from "../../function/FunctionApi";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

const createUrl = `${Url}OperationsAndMaintenaceRequest/create-or-update-order-operationAndMaintence`;
const updateUrl = `${Url}OperationsAndMaintenaceRequest/update`;

const Form = ({ userData, apiData }) => {
    const [formData, setFormData] = useState({
        ProjectType: '',
        FaultNumber: '',
        FaultType: '',
        District: '',
        Resources: '',
        Contractor: '',
        Consultant: '',
        Note: '',
        SafetyViolationsExist: ''
    });

    const [fileData, setFileData] = useState({
        ModelPhotos: [],
        SitePhotos: [],
        SafetyWastePhotos: [],
    });

    const fileInputRefs = {
        ModelPhotos: useRef(null),
        SitePhotos: useRef(null),
        SafetyWastePhotos: useRef(null),
    };

    const [errorMessage, setErrorMessage] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingArchive, setLoadingArchive] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [error, setError] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0); // لتتبع تقدم الإرسال
    const token = userData?.token;

    const projectTypeMapping = {
        'طوارئ': 1,
        'احلال': 2,
        'التعزيز': 3,
        'الجهد المتوسط': 4,
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'ProjectType') {
            setFormData((prev) => ({
                ...prev,
                ProjectType: projectTypeMapping[value]
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    useEffect(() => {
        if (apiData) {
            setFormData({
                ProjectType: apiData.projectType,
                FaultNumber: apiData.faultNumber || '',
                FaultType: apiData.faultType || '',
                Contractor: apiData.contractor || '',
                District: apiData.district || '',
                Resources: apiData.resources || '',
                Station: apiData.station || '',
                Consultant: apiData.consultant || '',
                Note: apiData.note || '',
                SafetyViolationsExist: apiData.isExist ? 'true' : 'false'
            });
            setFileData({
                ModelPhotos: apiData.modelPhotos || [],
                SitePhotos: apiData.sitePhotos || [],
                SafetyWastePhotos: apiData.safetyWastePhotos || [],
            });
        }
    }, [apiData]);

    const handleFileChange = (event, fileType) => {
        const uploadedFiles = Array.from(event.target.files);
        const newFiles = uploadedFiles.filter((file) => file.size <= 5 * 1024 * 1024);
        const rejectedFiles = uploadedFiles.filter((file) => file.size > 5 * 1024 * 1024);

        if (rejectedFiles.length) {
            setError('بعض الملفات كانت كبيرة جدًا ولم يتم تحميلها.');
        } else {
            setError('');
        }

        setFileData((prev) => ({
            ...prev,
            [fileType]: [...prev[fileType], ...newFiles],
        }));
    };

    const handleFileDelete = (fileType, fileName) => {
        setFileData((prev) => ({
            ...prev,
            [fileType]: prev[fileType].filter((file) => file.name !== fileName),
        }));
    };

    const openFileSelector = (fileType) => {
        fileInputRefs[fileType].current.click();
    };

    const handleApiFileDelete = async (fileType, fileUrl) => {
        try {
            const response = await axios.delete(`${Url}/path/to/delete/${fileUrl}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setFileData((prev) => ({
                    ...prev,
                    [fileType]: prev[fileType].filter((item) => item.url !== fileUrl),
                }));
                alert('تم حذف الصورة بنجاح');
            }
        } catch (error) {
            console.error('Error deleting file', error);
            alert('فشل حذف الصورة. يرجى المحاولة مرة أخرى.');
        }
    };

    const handleSubmit = async (isArchive) => {
        setLoadingSave(true);
        setUploadProgress(0); // إعادة تعيين تقدم الإرسال

        const submissionData = new FormData();
        const changedData = {};

        Object.entries(formData).forEach(([key, value]) => {
            if (!apiData || value !== apiData[key]) {
                changedData[key] = value;
                submissionData.append(key, value);
            }
        });

        submissionData.append('isArchive', isArchive);

        Object.entries(fileData).forEach(([key, files]) => {
            files.forEach((file) => {
                submissionData.append(key, file);
            });
        });

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percent = Math.floor((loaded * 100) / total);
                    setUploadProgress(percent); // تحديث نسبة الإرسال
                }
            };

            if (apiData) {
                submissionData.append('OrderNumber', apiData.faultNumber);
                await axios.put(updateUrl, submissionData, config);
            } else {
                await axios.post(createUrl, submissionData, config);
            }
            setShowSuccessModal(true);
        } catch (error) {
            let errorMessage = 'فشل إرسال النموذج. يرجى المحاولة مرة أخرى.';
            
            // التحقق من خطأ 413
            if (error.message === "Network Error") {
                errorMessage = 'حجم البيانات المرفوعة كبير جدًا. يرجى تقليل حجم الملفات والمحاولة مرة أخرى.';
            } else if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
        
            setErrorMessage(errorMessage);
            setShowErrorModal(true);
        } finally {
            setLoadingSave(false);
        }
    };


    const renderUploadSection = (label, fileType, buttonLabel, multiple = true) => (
        <div className="upload-section">
            <h4>{label}</h4>
            <div className="upload-box">
                <span className="upload-icon">
                    <img src="path/to/upload-icon.png" alt='Upload' />
                </span>
                <p>{buttonLabel}</p>
                <input
                    type="file"
                    onChange={(e) => handleFileChange(e, fileType)}
                    multiple={multiple}
                    ref={fileInputRefs[fileType]}
                    style={{ display: 'none' }}
                    accept=".jpg,.jpeg,.png,.pdf"
                />
                <div className="button-group">
                    <button type="button" className="upload-button" onClick={() => openFileSelector(fileType)}>رفع ملف</button>
                </div>
            </div>
            <div className="file-list">
                {!apiData &&fileData[fileType].map((file, index) => (
                    <div key={index} className="file-item">
                        {file instanceof File ? (
                            file.type === 'application/pdf' ? (
                                <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer" className="file-link">
                                    <img src="path/to/pdf-icon.png" alt="PDF" />
                                </a>
                            ) : (
                                <img src={URL.createObjectURL(file)} alt="Uploaded file" className="uploaded-image" />
                            )
                        ) : null}
                        <button
                            className="delete-button"
                            onClick={() => handleFileDelete(fileType, file.name)}
                        >
                            <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                    </div>
                ))}
                {apiData && fileData[fileType]?.map((item, index) => (
                    <div key={index} className="file-item">
                        {item.url.endsWith('.pdf') ? (
                            <a href={`${item.url}`} target="_blank" rel="noopener noreferrer" className="file-link">
                                <img src="path/to/pdf-icon.png" alt="PDF" />
                            </a>
                        ) : (
                            <img src={`${item.url}`} alt="Uploaded file" className="uploaded-image" />
                        )}
                        <button
                            className="delete-button"
                            onClick={() => handleApiFileDelete(fileType, item.url)}
                        >
                            <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="form-container" dir='rtl'>
            <div className='radio-container'>
                <div className='container'>
                    <h2>قم بتحديد نوع المشروع</h2>
                    <div className="radio-group">
                        {Object.keys(projectTypeMapping).map((type) => (
                            <label key={type}>
                                <input
                                    type="radio"
                                    name="ProjectType"
                                    value={type}
                                    checked={formData.ProjectType === projectTypeMapping[type]}
                                    onChange={handleChange}
                                />
                                <span className="radio-circle" />
                                <span>{type}</span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>

            <div className='FormData'>
                <div className='container'>
                    <h3>بيانات الطلب</h3>
                    <p>Get a Quote Immediately Upon Form Submission</p>
                    <div className="input-group">
                        <input
                            type="text"
                            name="FaultNumber"
                            placeholder="رقم العطل"
                            value={formData.FaultNumber}
                            onChange={handleChange}
                            required
                            readOnly={!!apiData} 
                        />
                        <input
                            type="text"
                            name="FaultType"
                            placeholder="نوع العطل"
                            value={formData.FaultType}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            name="District"
                            placeholder="الحي"
                            value={formData.District}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="Resources"
                            placeholder="المواد"
                            value={formData.Resources}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            name="Contractor"
                            placeholder="المقاول"
                            value={formData.Contractor}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="Consultant"
                            placeholder="الاستشاري"
                            value={formData.Consultant}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    {renderUploadSection("النموزج", "ModelPhotos", "تصوير مستندات مع الختم")}
                    {renderUploadSection("صور الموقع", "SitePhotos", "صور الموقع")}

                   <div className="violations">
                        <h4>مخالفات السلامة</h4>
                        <div className="radio-group">
                            {['exists', 'not-exists'].map((value) => (
                                <label key={value}>
                                    <input
                                        type="radio"
                                        name="safetyViolation"
                                        value={value}
                                        checked={formData.safetyViolation === value}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-circle" />
                                    {value === 'exists' ? 'يوجد' : 'لا يوجد'}
                                </label>
                            ))}
                        </div>
                    </div>

                    {formData.safetyViolation === 'exists' && renderUploadSection('صور مخالفات السلامة', 'SafetyWastePhotos', 'صور المخالفات')}

                    <textarea
                        name="Note"
                        placeholder="ملاحظات"
                        value={formData.Note}
                        onChange={handleChange}
                        rows="4"
                    ></textarea>

                    {error && <div className="error-message">{error}</div>}
                    <div className="button-group">
                        <button
                            className="submit-button"
                            style={{ background: 'rgba(76, 175, 79, 1)', color: '#fff' }}
                            onClick={() => handleSubmit(false)} disabled={loadingSave}>
                            {loadingSave ? 'جاري التحميل...' : 'إرسال'}
                        </button>

                        <button 
                         className="submit-button"
                         style={{ background: 'rgba(244, 67, 54, 1)', color: 'white' }}
                        onClick={() => handleSubmit(true)} disabled={loadingArchive}>
                            {loadingArchive ? 'جاري التحميل...' : 'أرشفة'}
                        </button>
                    </div>
                </div>
            </div>
            
            <Modal show={loadingSave} onHide={() => setLoadingSave(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>جاري الإرسال</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>نسبة الإرسال: {uploadProgress}%</p>
                    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                </Modal.Body>
            </Modal>

            {/* Success Modal */}
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>نجاح</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>تم إرسال النموذج بنجاح!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSuccessModal(false)}>إغلاق</Button>
                </Modal.Footer>
            </Modal>

            {/* Error Modal */}
            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>خطأ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{error}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowErrorModal(false)}>إغلاق</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Form;
