import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import UploadIcon from "../../Image/UploadIcon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import { Url } from "../../function/FunctionApi";

const createUrl = `${Url}NewProject/create-or-update-newProject`;
const updateUrl = `${Url}NewProject/update`;

const RadioGroup = ({ label, options, name, selectedValue, onChange }) => (
    <div className="violations">
        <h4>{label}</h4>
        <div className="radio-group">
            {options.map((option) => (
                <label key={option.value}>
                    <input
                        type="radio"
                        name={name}
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={onChange}
                    />
                    <span className="radio-circle" />
                    {option.label}
                </label>
            ))}
        </div>
    </div>
);

const Form = ({ userData, apiData }) => {
    const [formData, setFormData] = useState({
        ProjectType: '',
        FaultNumber: '',
        FaultType: '',
        District: '',
        Resources: '',
        Contractor: '',
        Consultant: '',
        Note: '',
        SafetyViolationsExist: '',
    });

    const [fileData, setFileData] = useState({
        ModelPhotos: [],
        SitePhotos: [],
        SafetyWastePhotos: [],
    });

    const fileInputRefs = {
        ModelPhotos: useRef(null),
        SitePhotos: useRef(null),
        SafetyWastePhotos: useRef(null),
    };

    const [errorMessage, setErrorMessage] = useState('');
    const token = userData?.token;
    const [showModal, setShowModal] = useState({ success: false, error: false });
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (apiData) {
            setFormData({
                ProjectType: apiData.projectType || '',
                FaultType: apiData.faultType || '',
                FaultNumber: apiData.faultNumber || '',
                Contractor: apiData.contractor || '',
                District: apiData.district || '',
                Consultant: apiData.consultant || '',
                Resources: apiData.resources || '',
                Note: apiData.note || '',
                SafetyViolationsExist: apiData.safetyViolationsExist ? 'exists' : 'not-exists'
            });
            setFileData({
                ModelPhotos: apiData.modelPhotos || [],
                SitePhotos: apiData.sitePhotos || [],
                SafetyWastePhotos: apiData.safetyWastePhotos || [],
            });
        }
    }, [apiData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const openFileSelector = (fileType) => {
        fileInputRefs[fileType].current.click();
    };

    const handleFileChange = (event, fileType) => {
        const uploadedFiles = Array.from(event.target.files);
        const newFiles = uploadedFiles.filter((file) => file.size <= 5 * 1024 * 1024);

        if (newFiles.length < uploadedFiles.length) {
            setErrorMessage('بعض الملفات كانت كبيرة جدًا ولا يمكن رفعها.');
            setShowModal({ success: false, error: true });
        } else {
            setErrorMessage('');
        }

        setFileData((prev) => ({
            ...prev,
            [fileType]: [...prev[fileType], ...newFiles],
        }));
    };

    const handleFileDelete = (fileType, fileName) => {
        setFileData((prev) => ({
            ...prev,
            [fileType]: prev[fileType].filter((file) => file.name !== fileName),
        }));
    };

    const handleSubmit = async (isArchive) => {
        const submissionData = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            submissionData.append(key, value);
        });

        submissionData.append('isArchive', isArchive);

        Object.entries(fileData).forEach(([key, files]) => {
            files.forEach(file => {
                submissionData.append(key, file);
            });
        });

        setLoading(true);
        setUploadProgress(0); 

        try {
            const url = apiData ? updateUrl : createUrl;
            const method = apiData ? 'PUT' : 'POST';

            await axios({
                method,
                url,
                data: submissionData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            });

            setShowModal({ success: true, error: false });
        } catch (error) {
            let errorMessage = 'فشل إرسال النموذج. يرجى المحاولة مرة أخرى.';
            
            // التحقق من خطأ 413
            if (error.message === "Network Error") {
                errorMessage = 'حجم البيانات المرفوعة كبير جدًا. يرجى تقليل حجم الملفات والمحاولة مرة أخرى.';
            } else if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
        
            setErrorMessage(errorMessage); 
            setShowModal({ success: false, error: true });
        } finally {
            setLoading(false);
        }
    };
    const renderUploadSection = (label, fileType, buttonLabel, multiple = true) => (
        <div className="upload-section">
            <h4>{label}</h4>
            <div className="upload-box">
                <span className="upload-icon">
                    <img src={UploadIcon} alt='Upload' />
                </span>
                <p>{buttonLabel}</p>
                <input
                    type="file"
                    onChange={(e) => handleFileChange(e, fileType)}
                    multiple={multiple}
                    ref={fileInputRefs[fileType]}
                    style={{ display: 'none' }}
                    accept=".jpg,.jpeg,.png,.pdf"
                />
                <div className="button-group">
                    <button type="button" className="upload-button" onClick={() => openFileSelector(fileType)}>رفع ملف</button>
                </div>
            </div>
            <div className="file-list">
                {!apiData && fileData[fileType].map((file, index) => (
                    <div key={index} className="file-item">
                        {file instanceof File ? (
                            file.type === 'application/pdf' ? (
                                <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer" className="file-link">
                                    <img src="path/to/pdf-icon.png" alt="PDF" />
                                </a>
                            ) : (
                                <img src={URL.createObjectURL(file)} alt="Uploaded file" className="uploaded-image" />
                            )
                        ) : null}
                        <button
                            className="delete-button"
                            onClick={() => handleFileDelete(fileType, file.name)}
                        >
                            <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                    </div>
                ))}
                {apiData && fileData[fileType]?.map((item, index) => (
                    <div key={index} className="file-item">
                        {item.url.endsWith('.pdf') ? (
                            <a href={`${item.url}`} target="_blank" rel="noopener noreferrer" className="file-link">
                                <img src="path/to/pdf-icon.png" alt="PDF" />
                            </a>
                        ) : (
                            <img src={`${item.url}`} alt="Uploaded file" className="uploaded-image" />
                        )}
                        <button
                            className="delete-button"
                            onClick={() => handleApiFileDelete(fileType, item.url)} // Use the new function
                        >
                            <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );

    const handleApiFileDelete = async (fileType, fileUrl) => {
        try {
            const response = await axios.delete(`${Url}/path/to/delete/${fileUrl}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setFileData((prev) => ({
                    ...prev,
                    [fileType]: prev[fileType].filter((item) => item.url !== fileUrl),
                }));
                alert('تم حذف الصورة بنجاح');
            }
        } catch (error) {
            console.error('Error deleting file', error);
            alert('فشل حذف الصورة. يرجى المحاولة مرة أخرى.');
        }
    };


    return (
        <div className="form-container" dir='rtl'>
            <div className='container'>
                <div className='FormData'>
                    <h3>بيانات الطلب</h3>
                    <p>Get a Quote Immediately Upon Form Submission</p>

                    {[['نوع المشروع', 'رقم العطل'], ['نوع العطل', 'الحي'], ['الموارد', 'المقاول'], ['الاستشاري']].map((pair, index) => (
                        <div key={index} className="input-group">
                            {pair.map((placeholder, idx) => (
                                <input
                                    key={idx}
                                    type="text"
                                    name={['ProjectType', 'FaultNumber', 'FaultType', 'District', 'Resources', 'Contractor', 'Consultant'][index * 2 + idx]}
                                    placeholder={placeholder}
                                    value={formData[['ProjectType', 'FaultNumber', 'FaultType', 'District', 'Resources', 'Contractor', 'Consultant'][index * 2 + idx]]}
                                    onChange={handleChange}
                                    required
                                />
                            ))}
                        </div>
                    ))}

                    {renderUploadSection("النموزج", "ModelPhotos", "تصوير مستندات مع الختم")}
                    {renderUploadSection("صور الموقع", "SitePhotos", "صور الموقع")}

                    {/* Safety Violations Section */}
                    <RadioGroup
                        label='مخالفات السلامة'
                        options={[{ label: 'يوجد', value: "exists" }, { label: 'لا يوجد', value: 'not-exists' }]}
                        name='SafetyViolationsExist'
                        selectedValue={formData.SafetyViolationsExist}
                        onChange={handleChange}
                    />

                    {formData.SafetyViolationsExist === "exists" && (
                        renderUploadSection('صور مخالفات السلامة', 'SafetyWastePhotos', 'صور المخالفات')
                    )}

                    <div className="input-group">
                        <textarea
                            name="Note"
                            placeholder="الملاحظات"
                            value={formData.Note}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Submission Buttons */}
                    <div className="button-group">
                        <button
                            type="button"
                            className="submit-button"
                            style={{ background: 'rgba(76, 175, 79, 1)', color: '#fff' }}
                            onClick={() => handleSubmit(false)}
                            disabled={loading}
                        >
                            {loading ? 'جاري التحميل...' : 'إرسال'}
                        </button>
                        <button
                            type="button"
                            className="submit-button"
                            style={{ background: 'rgba(244, 67, 54, 1)', color: 'white' }}
                            onClick={() => handleSubmit(true)}
                            disabled={loading}
                        >
                            {loading ? 'جاري التحميل...' : 'أرشفة'}
                        </button>
                    </div>
                </div>
            </div>
            {/* Progress Modal */}
            <Modal show={loading} onHide={() => setLoading(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>تحميل</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                </Modal.Body>
            </Modal>
            {/* Modals for Success and Error */}
            <Modal show={showModal.success} onHide={() => setShowModal({ ...showModal, success: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>نجاح</Modal.Title>
                </Modal.Header>
                <Modal.Body>تم إرسال النموذج بنجاح!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal({ ...showModal, success: false })}>
                        إغلاق
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModal.error} onHide={() => setShowModal({ ...showModal, error: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>خطأ</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal({ ...showModal, error: false })}>
                        إغلاق
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Form;
